/* .services-sketch {
    position: fixed;
} */

.services-container {
  background-color: black;
  padding: 2rem;
}

.services-container h4 {
  text-transform: uppercase;
  padding-bottom: 1rem;
}

.services-container h5 {
  text-transform: uppercase;
  padding-bottom: 1rem;
}

.catalog {
  display: flex;
  background-color: black;
  padding: 5rem 3rem 10rem 3rem;
  gap: 2rem;
}

.catalog-el {
  flex-basis: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.quote {
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  padding: 1rem;
  margin-bottom: 1rem;
  align-self: stretch;
  text-transform: uppercase;
  border: white 1px solid;
}

.service-list {
  padding: 1rem 1rem 2rem 1rem;
}

.service {
  color: white;
  border-bottom: 1px solid white;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.contact-button {
  background-color: white;
  width: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  color: black;
  font-weight: 600;
  text-decoration: none;
  transition-property: background-color, color, font-weight;
  transition: all 1s ease-in-out;
  cursor: pointer;
}

.contact-button:hover {
  font-weight: 800;
  color: white;
  background-color: black;
  transition-property: background-color, color, font-weight;
  transition: all 1s ease-in-out;
}

.contact-button:active {
  font-weight: 300;
  transition: font-weight 30ms ease-in-out;
}

@media (max-width: 1000px) {
  .catalog {
    flex-direction: column;
  }

  .services-container h5 {
    padding-top: 2rem;
    margin-top: 2rem;
    font-size: 1.8rem;
    border-top: white 1px solid;
  }

  .services-container h4 {
    font-size: 2.5rem;
  }

  .quote {
    font-size: 20px;
    line-height: 24px;
  }

  .contact-button {
    font-size: 1.2rem;
  }
}
