.Bg-sketch {
    position: fixed;
}

@media (prefers-reduced-motion: no-preference) {
    .Logo {
      display: block;
      width: 100%;
      text-align: center;
      font-size: 200px;
      color: white;
      position: absolute;
      top: 35%;
      z-index: 2;
      text-indent: 50px;
      font-family: "AbismoZeroLogo", sans-serif;
      font-variation-settings: "wght" 100;
      --delay: 400ms;
      animation: breathe 4000ms infinite ease-in-out;
      animation-delay: var(--delay);
    }

    .Logo a {
      color: white;
      text-decoration: none;
    }
  
    .Logo:hover{
      cursor: pointer;
    }
    
    @keyframes breathe {
      0% {font-variation-settings: 'wght' 100;}
      50% {font-variation-settings: 'wght' 900;}
      100% { font-variation-settings: 'wght' 100;}
    }
  }

  @media (max-width: 1000px) {
    .Logo {
      text-indent: 20px;
      font-size: 230px;
      top: 25%;
      -moz-font-feature-settings: 'ss01';
      -webkit-font-feature-settings: 'ss01';
      font-feature-settings: 'ss01' on;
    }
  }

  @media (max-height: 400px) {
    .Logo{
      top: 15%;
    }
  }