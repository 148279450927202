.rock-p {
    text-align: left;
    padding: 3rem;
}

.three-rock-posters {
    display: flex;
    align-items: stretch;
    align-content: stretch;
    justify-items: stretch;
    justify-content: space-between;
    height: 600px;
    padding-top: 1rem;
}

.rockposter {
    flex-basis: calc(100%/3);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.swu-logo {
    background-position: center;
    height: 200px;
    grid-column: 1 / -1;
}

@media (max-width: 1200px) {
    .three-rock-posters {
        flex-direction: column;
        height: 1400px;
    }
}