.gallery {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 10px;
  padding-bottom: 4rem;
}

.gallery-header {
  grid-area: 1 / 1 / span 1 / span 2;
  height: 100px;
}

.gallery-item {
  padding-top: 50%;
  padding-bottom: 50%;
  text-decoration: none;
  background-position: center;
  background-size: cover;
}

.one-col {
  grid-column: 1 / -1;
  padding-top: 25%;
  padding-bottom: 25%;
}

.g-item-title {
  display: none;
  position: absolute;
  background-color: black;
  padding: 20px;
  font-size: 20px;
  line-height: 24px;
  pointer-events: none;
  width: fit-content;
}

.project-gallery {
  display: grid;
  width: 100%;
  padding-bottom: 6rem;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, auto);
  grid-auto-rows: 200px;
  grid-gap: 10px;
}

.project-header {
  grid-area: 1 / 1 / span 1 / -1;
  height: 100px;
}

.project-title {
  text-align: left;
  padding: 3rem;
  grid-area: 2 / 1 / span 1 / -1;
}

.project-description {
  text-align: left;
  padding: 3rem;
  grid-area: 3 / 1 / span 3 / span 3;
}

.project-container {
  grid-column: 1 / -1;
  grid-row-end: -1;
}

.project-paragraph {
  padding: 3rem;
  text-align: left;
}

.three-posters {
  display: flex;
  height: 400px;
  align-items: stretch;
  align-content: stretch;
  justify-items: stretch;
  justify-content: space-between;
}

.poster {
  flex-basis: calc(100% / 3);
  background-size: cover;
  background-position: center;
}

@media (max-width: 800px) {
  .three-posters {
    flex-direction: column;
    height: 1400px;
  }
}

@media (max-width: 1000px) {
  .gallery-header {
    grid-area: 1 / 1 / span 1 / span 1;
  }

  .gallery {
    grid-template-columns: 100%;
  }

  .one-col {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .project-title {
    text-align: center;
    margin-top: 100px;
    padding: 10px;
    grid-area: 2 / 1 / span 1 / -1;
  }
}
