.logo-holder {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0px 340px 0px;
}

.interactive-logo {
    font-size: 200px;
    font-family: 'AbismoZeroLogo';
    flex-basis: 90%;
    padding: 100px 0px 100px 0px;
    margin-bottom: 50px;
}

.slider-intro {
    margin-bottom: 40px;
    padding: 20px;
}

.var-slider {
    flex-basis: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider {
    max-width: 500px;
    min-width: 200px;
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 50%; /* Full-width */
    height: 5px; /* Specified height */
    background: white; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 1; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 5px; 
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: black;
    border: white 1px solid;
    cursor: pointer;
}
  
.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: black;
    border: white 1px solid;
    cursor: pointer;
}

.abismo-section {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
}
.abismo-section-section {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
}
.abismo-renders {
    flex-basis: 50%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.tarjetas {
    padding: 25%;
    background-image: url('./ABISMO_businessCard.png');
    background-size: cover;
    background-position: center;
}

.abismo-intro {
    text-align: center;
    padding: 100px 20%;
    display: flex;
    align-content: center;
}

.bolsa {
    padding: 25%;
    background-image: url('./ToteBag\ 1.png');
    background-size: cover;
    background-position: center;
}

.playera {
    padding: 25%;
    background-image: url('./PLAYERA_ABISMO_VERTICAL.png');
    background-size: cover;
    background-position: center;
}

@media (max-width: 800px) {
    .abismo-intro {
        padding: 10%;
    }
    .abismo-renders {
        flex-direction: column;
    }
    .bolsa {
        padding: 50%;
    }
    
    .playera {
        padding: 50%;
    }
    .logo-holder {
        margin: 20px 20px 100px 20px;
    }
    .interactive-logo {
        margin-bottom: 0px;
    }
}