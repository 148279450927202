@font-face {
  font-family: "AbismoZeroLogo";
  src: url("../src/util/fonts/AbismoZeroLogoGX.woff2") format("woff2-variations");
  font-weight: 100 800;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html {
  background-color: black;
  color: white;
  width: 100%;
  font-family: input-mono, 'Courier New', monospace;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-weight: 400;
  font-style: normal;
}

strong {
  font-weight: bold;
}

.black {
  font-weight: 800;
}

em {
  font-style: oblique;
}

a {
  color: white;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}

a:active {
  font-weight: bold;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

h1 {
  font-size: 6rem;
}
h2 {
  font-size: 5rem;
}
h3 {
  font-size: 4rem;
}
h4 {
  font-size: 3rem;
}
h5 {
  font-size: 2rem;
}
h6 {
  font-size: 1rem;
}

p {
  font-size: 1.2rem;
  line-height: 1.5rem;
}
figcaption {
  font-style: italic;
}