.peterhans-intro {
    grid-column: 2 / -2;
    grid-row-end: span 8;
}

.peterhans-posters {
    height: 700px;
    background-Size: contain;
    background-Position: center;
    background-Repeat: no-repeat;
    margin-bottom: 50px;
}

.petehans-items {
    grid-column: 1 / -1;
    grid-row-end: span 4;
    display: flex;
}

.peterhans-item {
    flex-basis: 50%;
    background-Size: cover;
    background-Position: center;
    background-Repeat: no-repeat;
}

@media (max-width: 1000px) {
    .peterhans-intro {
        grid-column: 1 / -1;
        grid-row-end: span 6;
        padding: 50px;
    }
    .peterhans-posters {
        height: 500px;
    }
    .petehans-items {
        flex-direction: column;
        grid-row-end: span 6;
    }
}