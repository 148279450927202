.about-sketch {
  position: fixed;
  z-index: 6;
}

.info-container {
  padding: 10vh 10% calc(3rem + 100px) 10%;
  background-color: transparent;
  display: flex;
  min-height: 100vh;
  z-index: 7;
}

.info-el {
  z-index: 7;
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
}

.about-us {
  z-index: 7;
  background-color: none;
  flex-basis: 40%;
  color: #333;
  text-align: left;
  padding-bottom: 4rem;
  background-color: rgba(0, 0, 0, 1);
}

.expandable {
  background-color: black;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.expand-button {
  background-color: black;
  border: none;
  outline: none;
  width: 4rem;
  height: inherit;
  cursor: pointer;
  text-shadow: none;
  transition: transform 200ms;
  padding-left: 1rem;
  padding-right: 1rem;
}

.expand-button :hover {
  transform: scale(1.2);
}

.expand-button :active {
  background-color: black;
}

.about-us h5 {
  padding: 2rem;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 2rem;
}

.about-us p {
  padding: 0 2rem 2rem 2rem;
  font-size: 24px;
  line-height: 28px;
  transition: display 600ms;
  background-color: black;
  color: white;
}

.about-us-photo {
  background-image: url('./lsp_eat.jpg');
  background-position: center;
  background-size: cover;
  flex-basis: 95%;
}

.us-photo {
  color: white;
  display: flex;
  flex-direction: row;
  text-align: right;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  flex-basis: 5%;
  background-color: black;
}

.we-are {
  height: 100vh;
}

.person-info {
  display: none;
}

@media (max-width: 1000px) {
  .info-container {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 100px 50px 50px 50px;
  }

  .info-el {
    flex-basis: auto;
  }

  .about-us {
    flex-basis: auto;
  }

  .about-us h5 {
    font-size: 24px;
    line-height: 28px;
  }

  .about-us p {
    font-size: 20px;
    line-height: 24px;
  }

  .about-us-photo {
    min-height: 50vh;
  }

  .us-photo {
    font-size: 16px;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
  }
}
