nav {
    max-width: 50%;
    min-width: 30%;
    padding: 10px;
    display: flex;
    position: absolute;
    height: 50px;
    align-items: stretch;
    align-content: stretch ;
    flex-flow: row wrap;
    justify-content:space-around;
    z-index: 400;
    top: 20px;
    left: 20px;
    border-right: 1px white solid;
    border-left: 1px white solid;
    background-color: rgba(0, 0, 0, 0.8);
    font-family: input-mono, 'Courier New', Courier, monospace;
}

nav:hover {
    animation: mover 1s infinite alternate;
}

@keyframes mover {
    0% { transform: translateY(2px); }
    100% { transform: translateY(4px); }
}

.nav-items {
    padding: 5px;
    font-size: 14px;
    flex-grow: 2;
    flex-shrink: 3;
    align-self: center;
    text-transform: uppercase;
    transition: ease-in 0.5s;
}

.nav-items:hover {
  font-weight: bolder;
}


.nav-items-active {
  flex-grow: 2;
  flex-shrink: 3;
  padding: 5px;
  font-size: 16px;
  text-transform: uppercase;
  align-self: center;
  font-weight: bold;
  transition: ease-out 0.5s;
}

.mobileMenu {
  color:white;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.nav-items-mobile{
  margin: 20px;
}

.nav-items-active-mobile {
  margin: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 16px;
  font-weight: bold;
  text-decoration: overline underline;
  transition: ease-out 0.5s;
}


@media (max-width: 1000px) {
    nav {
      flex-flow: column nowrap;
      min-width: 40%;
      max-width: 50%;
      height: auto;
    }
}