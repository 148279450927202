footer {
  font-size: 11px;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  color: white;
  padding: 40px 4%;
  position: fixed;
  bottom: 0px;
  z-index: 8;
  transition: opacity 1s ease;
}

footer .footInfo {
  flex-basis: 30%;
  text-align: left;
  font-size: 11px;
}

.socialMedia {
  flex-basis: 70%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.socialMedia p {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
  font-size: 11px;
}

.socialMedia p:hover {
  font-weight: bolder;
}

@media (max-width: 1000px) {
  footer {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    padding: 20px 0;
  }

  footer .footInfo {
    margin-top: 20px;
    flex-basis: 100%;
    text-align: center;
    font-size: 0.6rem;
  }

  .socialMedia {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 10px 0px;
  }

  .socialMedia p {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 15%;
    margin: 10px 20px;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .socialMedia {
    margin: 5px 0px;
  }

  .socialMedia p {
    font-size: 10px;
    flex-basis: 5%;
    margin: 5px 10px;
  }
}
