.App {
  text-align: center;
  width: 100%;
  background-color: black;
}

@media (prefers-reduced-motion: no-preference) {
  .homeLogo {
    display: block;
    text-decoration: none;
    font-size: 80px;
    font-family: 'AbismoZeroLogo', sans-serif;
    -moz-font-feature-settings: 'ss01';
    -webkit-font-feature-settings: 'ss01';
    font-feature-settings: 'ss01' on;
    font-variation-settings: 'wght' 150;
    --delay: 400ms;
    animation: breathe 4000ms infinite ease-in-out;
    animation-delay: var(--delay);
    position: fixed;
    top: 80px;
    right: 40px;
    z-index: 9;
    text-shadow: 3px 3px black;
  }

  @keyframes breathe {
    0% {
      font-variation-settings: 'wght' 150;
    }
    50% {
      font-variation-settings: 'wght' 900;
    }
    100% {
      font-variation-settings: 'wght' 150;
    }
  }
}

#lang-title {
  color: white;
  z-index: 10;
  position: fixed;
  font-size: 16px;
  top: 20px;
  right: 40px;
  width: 90px;
  height: 30px;
  text-align: right;
  font-weight: 800;
  text-transform: uppercase;
}

.lang {
  color: white;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  position: fixed;
  font-size: 14px;
  top: 45px;
  right: 40px;
  width: 90px;
  height: 30px;
  text-align: center;
  align-items: center;
}
.lang td {
  cursor: pointer;
  background-color: black;
  padding: 8px;
  font-weight: 400;
  border: white 1px solid;
  transition: ease 100ms;
}
.lang td:hover {
  transition: ease 100ms;
  font-weight: bold;
}
.lang td:active {
  color: black;
  background-color: white;
  font-weight: bold;
}

.section-container {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  cursor: default;
  z-index: 6;
}

button {
  border: 1px white solid;
  background-color: black;
  color: white;
  font-family: input-mono;
  font-size: 16px;
  padding: 10px;
  outline: none;
  text-align: center;
}

.contactUs {
  padding: 0px;
  text-transform: uppercase;
  border: 2px white solid;
  border-radius: 130px;
  font-size: 16px;
  height: 130px;
  width: 130px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  transition: ease 100ms;
  cursor: pointer;
}

.contactUs:hover {
  transition: ease 100ms;
  font-weight: bold;
}

.contactUs:active {
  color: black;
  background-color: white;
  border-color: black;
}

@media (max-width: 1000px) {
  .contactUsStyle {
    width: 90px;
    height: 90px;
    border-radius: 80px;
    font-size: 11px;
  }

  .homeLogo {
    right: 20px;
  }

  #lang-title {
    right: 20px;
  }

  .lang {
    right: 20px;
  }
}
