.headGraphic {
    grid-row-end: span 1;
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.COVID19-poster {
    height: 700px;
    background-Size: cover;
    background-Position: center;
    background-Repeat: no-repeat;
    margin-bottom: 50px;
}

.COVID19-main-image {
    grid-column: 1 / -1;
    grid-row-end: span 5;
    background-Size: 100%;
    height: 100%;
    background-Position: center;
    background-Repeat: no-repeat;
    margin-bottom: 50px;
}

.COVID19-close-image {
    grid-column: 1 / -1;
    grid-row-end: span 6; 
    width: 100%;
}

.covid-description {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    grid-column: 2 / -2;
    grid-row-end: span 3;
}

.covid-description h5{
    padding-bottom: 25px;
}

.covid-description img{
    padding-top: 25px;
}

.reference{
    grid-row-end: span 1;
    grid-column: 1 / -1;
    padding: 0px 50px 50px 50px;
}

@media (max-width: 1000px) {
    .covid-description {
        padding: 50px;
        grid-column: 1 / -1;
        grid-row-end: span 2;
    }
    .COVID19-poster {
        height: 400px;
        background-Size: cover;
    }
    .COVID19-main-image {
        grid-row-end: span 3;
    }
    .COVID19-close-image {
        grid-row-end: span 2;
    }
    .reference{
        grid-row-end: span 1;
        grid-column: 1 / -1;
        padding: 0px 50px 0px 50px;
    }
}