.project-container img{
    max-width: 100%;
}

.code-output {
    display: flex;
    height: 700px;
    padding: 2rem;
}

.code-output-item {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}


.window {
    flex-basis: 60%;
}

.story {
    flex-basis: 40%;
}

@media (max-width: 1000px) {
    .code-output {
        flex-direction: column;
        height: 1000px;
        padding: 0rem;
    }
    
    .window {
        flex-basis: 50%;
    }
    
    .story {
        flex-basis: 50%;
    }
}